<template>
  <div class="card p-2" style="min-height: 200px">
    <div class="table-responsive">
      <table class="table">
        <thead>
        <tr>
          <th>Employee</th>
          <th>Leave Type</th>
          <th>From Date</th>
          <th>To Date</th>
          <th>Applied For(Days)</th>
          <th>Leave Balance</th>
          <th>Purpose</th>
          <th>Status</th>
          <th>Comments</th>
          <th>Action</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item, i) in tableItems" :key="i">
          <td>
             {{ item.contact_profile_name }} <br>
            {{ item.contact_profile_designation }}
          </td>
          <td>{{ item.leave_policies_name }}</td>
          <td>{{ item.start_date }}</td>
          <td>{{ item.end_date }}</td>
          <td>{{ item.number_of_days }}</td>
          <td>{{ item.remaining_leave_balance }}</td>
          <td>{{ item.purpose }}</td>
          <td>{{ item.status }}</td>
          <td>{{ item.remarks }}</td>
          <td>
            <button v-if="item.status === 'pending'" class="btn btn-primary btn-sm"
                    @click="goToEdit(item)"
                    style="cursor: pointer"
            >
              <i class="fas fa-eye"></i>
            </button>
          </td>
        </tr>
        </tbody>
      </table>
      <p v-if="tableItems.length < 0" class="text-center mt-5">No data</p>
    </div>

    <LeaveApplicationApprovalModal
        :isEdit="isEdit"
        ref="registerTypeModalRef"
        :formData="formData"
        @onSubmit="handleSubmit"
        @onClose="clearModal"
    />

  </div>
</template>

<script setup>
import {useRoute, useRouter} from "vue-router";
import LeaveApplicationApprovalModal from '@/components/molecule/company/hrm-payroll/leave-application-approval/LeaveApplicationApprovalModal.vue';
import {computed, inject, ref} from "vue";
import handleHrPayroll from "@/services/modules/hrPayroll/hrPayroll";

const props  = defineProps({
  tableItems     : Object,
});
const route  = useRoute();
const router = useRouter();
const registerTypeModalRef = ref(null);
const isEdit = ref(false)
const showError = inject('showError');
const showSuccess = inject('showSuccess')
const editData = ref(false)
const loading = ref(false)
const isCreate = ref(false)

const companyId = computed(() => {
  return route.params.companyId
})
let leaveApprovalData = {}
const formData = ref({
  company_id: companyId,
  status: 'pending',
  leave_application_id: '',
  remarks:'',
})

const $emit = defineEmits(['getLeaveApplicationApproval'])

const {
  fetchSingleLeaveApplication,
  createLeaveApplicationApproval,
  fetchSingleLeaveApplicationLog,
  updateLeaveApplicationApproval
} = handleHrPayroll()
const openModal = () => {
  isEdit.value = false
  registerTypeModalRef.value.toggleModal()
}

const handleSubmit =  (status) => {
  if (!confirm(`Are you sure you want ${status} to action this application?`)) {
    return;
  }
  if (isCreate.value){
    handleCreate(status)
    return
  }
  handleUpdate(status)

}

const handleCreate = (status) => {
  leaveApprovalData.status = status
  leaveApprovalData.company_id  = companyId.value;
  leaveApprovalData.leave_application_id  = formData.value.leave_application_id;
  leaveApprovalData.remarks  = formData.value.remarks;

  createLeaveApplicationApproval(leaveApprovalData, getQuery())
      .then(res => {
        if (!res.status) {
          showError(res.message)
          return
        }
        showSuccess(res.message)
        $emit('getLeaveApplicationApproval')
        registerTypeModalRef.value.toggleModal()

      })
      .catch(err => {
        console.log(err)
      }).finally(() => {
        loading.value = registerTypeModalRef.value.loading = false;
  })
}

const handleUpdate = (status) => {
  leaveApprovalData.id = editData.value.id
  leaveApprovalData.status = status
  leaveApprovalData.company_id  = companyId.value;
  leaveApprovalData.leave_application_id  = formData.value.leave_application_id;
  leaveApprovalData.remarks  = formData.value.remarks;

  updateLeaveApplicationApproval(leaveApprovalData,leaveApprovalData.id, getQuery())
      .then(res => {
        console.log(res)
        if (!res.status) {
          showError(res.message)
          return
        }
        showSuccess(res.message)
        $emit('getLeaveApplicationApproval')
        registerTypeModalRef.value.toggleModal()
      })
      .catch(err => {
        console.log(err)
      }).finally(() => {
         loading.value = registerTypeModalRef.value.loading = false;
  })
}

const clearModal = () => {
  formData.value = {
    company_id: companyId,
    status: 'pending',
    leave_application_id: '',
    remarks:'',
  }
}

function getQuery() {
  return `?company_id=${route.params.companyId}`;
}

const goToEdit = (item) => {
  isEdit.value = true
  if (item.leave_application_log_id){
    const fetchData  = fetchSingleLeaveApplicationLog(item.leave_application_log_id, getQuery())
    fetchData.then(res => {
      if (res.status){
        registerTypeModalRef.value?.toggleModal(isEdit.value)
        editData.value = res.data;
        formData.value.leave_application_id = editData.value.leave_application_id;
        formData.value.remarks = editData.value.remarks;
      }
    });
  }

  if (!item.leave_application_log_id){
    const fetchSingleData = fetchSingleLeaveApplication(item.id, getQuery());
    fetchSingleData.then(res => {
      if (res.status){
        registerTypeModalRef.value?.toggleModal(isEdit.value)
        editData.value = res.data;
        isCreate.value = true;
        formData.value.leave_application_id = editData.value.id;
      }
    });
  }
}

</script>
