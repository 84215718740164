<template>
  <div v-if="isOpen" class="modal fade show" aria-labelledby="addNewCardTitle"
       style="display: block; background: #1c1d1c33">
    <div class="modal-dialog modal-dialog-centered ">
      <div class="modal-content">
        <div class="row">
          <div class="col-12">
            <div class="d-flex justify-content-between px-1 my-1 align-item-center">
              <h1 class="mb-0">Leave Application Approval</h1>
              <button
                  @click="toggleModal"
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
              ></button>
            </div>
          </div>
        </div>
        <hr class="mt-0">

        <div class="pb-2">
          <!-- form -->
            <div class="row gy-2 px-2">
              <div class="col-12">
                <label class="align-middle">Remarks</label>
                <input
                    class="form-control invoice-edit-input date-picker flatpickr-input flatpickr-mobile"
                    tabindex="1"
                    type="text"
                    required
                    placeholder="Remarks...."
                    v-model="formData.remarks"
                >
              </div>
            </div>

            <div class="col-12 text-center">
              <hr class="mt-2">
              <button  class="btn btn-danger me-1 mt-1" @click="handleSubmit('reject')">
                Reject
              </button>
              <button  class="btn btn-primary me-1 mt-1" @click="handleSubmit('return')">
                Return
              </button>
              <button  class="btn btn-success me-1 mt-1" @click="handleSubmit('approved')">
                Approved
              </button>
              <button @click.prevent="toggleModal" class="btn btn-outline-secondary mt-1 waves-effect">
                Cancel
              </button>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {onMounted, ref} from "vue";
import handleContact from '@/services/modules/contact'
import {useRoute} from "vue-router";
import handleHrPayroll from "@/services/modules/hrPayroll/hrPayroll";

const props = defineProps({
  formData: Object,
  title: {
    type: String,
    default: ''
  },
  isEdit: {
    type: Boolean,
    default: false
  }
})

const $emit = defineEmits(['onSubmit', 'onClose'])

const route = useRoute()
let isOpen = ref(false)
const loader = ref(false)
const schema = ref({})
let contacts = ref([])
const status = ref([]);

const {
  fetchTypes,
} = handleHrPayroll()
const { fetchContactProfiles } = handleContact()

const toggleModal = (isEdit = false) => {
  isOpen.value = !isOpen.value
  if (!isEdit) {
    $emit('onClose')
  }
}

const handleSubmit = (status) => {
  $emit("onSubmit",status)
}

defineExpose({toggleModal, loader})

</script>
